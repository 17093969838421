@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$button-size: 4rem;
$button-size-large: 5.6rem;
$button-border-width: 1px;

/* BUTTON
========================================================================== */
.atom__button-container {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;

  height: $button-size;
  padding: 0 $s-md;

  gap: $s-zs;
  border: $button-border-width solid $c-primary;

  background-color: $c-primary;
  color: $c-base-white;

  font-size: $text-size-tiny;
  line-height: $text-line-height-default;
  text-decoration: none;
  white-space: nowrap;
  transition: background-color ease $basic-transition;

  &:hover,
  &:focus {
    outline: none;
    background-color: $c-primary-dark;
    text-decoration: none;
    transition: background-color ease $basic-transition;
  }

  /* STATE
  ========================================================================== */
  &[disabled] {
    border-color: $c-gray-3;
    background-color: $c-gray-3;
    pointer-events: none;
  }

  /* SIZES
  ========================================================================== */
  &[data-size='large'] {
    height: $button-size-large;
    font-size: $text-size-small;
    line-height: $text-line-height-medium;
  }

  /* COLORS
  ========================================================================== */
  &[data-color='light'] {
    border-color: $c-base-black;
    background-color: $c-base-white;
    color: $c-base-black;

    &:hover,
    &:focus {
      background-color: $c-gray-1;
    }

    &[disabled] {
      border-color: $c-gray-4;
      color: $c-gray-4;
    }
  }

  &[data-color='dark'] {
    border-color: $c-base-black;
    background-color: $c-base-black;
    color: $c-base-lightest;

    &:hover,
    &:focus {
      border-color: $c-base-lightest;
      background-color: $c-gray-6;
    }

    &[disabled] {
      border-color: $c-gray-4;
      color: $c-gray-4;
    }
  }

  &[data-color='blue'] {
    border-color: $c-blue-3;
    background-color: $c-blue-3;
    color: $c-base-lightest;

    &:hover,
    &:focus {
      border-color: $c-base-lightest;
      background-color: $c-blue-4;
    }

    &[disabled] {
      border-color: $c-gray-4;
      color: $c-gray-4;
    }
  }

  &[data-color='ghost'] {
    &:hover,
    &:focus {
      color: $c-base-black !important;
    }
  }

  &[data-color='ghost-2'] {
    border-color: $c-base-black;
    background-color: transparent;
    color: $c-base-black;
    transition: background-color ease $basic-transition, color ease $basic-transition,
      border-color ease $basic-transition;

    &:hover,
    &:focus {
      border-color: $c-gray-4;
      background-color: $c-base-white;
      color: $c-gray-4;
      transition: background-color ease $basic-transition, color ease $basic-transition,
        border-color ease $basic-transition;
    }

    &[disabled] {
      border-color: $c-gray-4;
      color: $c-gray-4;
    }
  }

  &[data-color='ghost-3'] {
    &:hover,
    &:focus {
      color: $c-gray-7 !important;
    }
  }

  &[data-color='ghost-4'] {
    &:hover,
    &:focus {
      color: $c-blue-1 !important;
    }
  }

  &[data-color='ghost-5'] {
    &:hover,
    &:focus {
      color: $c-blue-2 !important;
    }
  }

  &[data-color='ghost-black'] {
    border-color: $c-base-black;
    background-color: transparent;
    color: $c-base-black;
    transition: background-color ease $basic-transition, color ease $basic-transition,
      border-color ease $basic-transition;

    &:hover,
    :focus {
      border-color: none;
      background-color: $c-base-black;
      color: $c-base-white;
      transition: background-color ease $basic-transition, color ease $basic-transition,
        border-color ease $basic-transition;
    }

    &[disabled] {
      border-color: $c-gray-4;
      color: $c-gray-4;
    }
  }

  &[data-color='ghost'],
  &[data-color='ghost-3'],
  &[data-color='ghost-4'],
  &[data-color='ghost-5'] {
    border-color: $c-base-white;
    background-color: transparent;
    color: $c-base-white;

    &:hover,
    &:focus {
      border-color: $c-base-white;
      background-color: $c-base-white;
      opacity: 1 !important;

      svg {
        color: currentColor !important;
        path {
          stroke: currentColor;
          fill: currentColor !important;

          color: currentColor !important;
        }
      }
    }
  }

  &[data-bold=true] {
    font-weight: 500 !important;
  }

  &[data-bold=false] {
    font-weight: 400 !important;
  }
}
