@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.atom__title-container {
  position: relative;
  color: $c-secondary;

  /* SIZES
  ========================================================================== */
  &[data-variant='1'] {
    font-weight: 500;
    font-size: 4rem;
    line-height: $text-line-height-large;

    @include gt-tablet {
      font-size: $text-size-xlarge;
      line-height: $text-line-height-xlarge;
    }
  }

  &[data-variant='2'] {
    font-weight: 500;
    font-size: $text-size-medium;
    line-height: $text-line-height-xmedium;

    @include gt-tablet {
      font-size: $text-size-large;
      line-height: $text-line-height-large;
    }
  }

  &[data-variant='3'] {
    font-weight: 500;
    font-size: $text-size-small;
    line-height: $text-line-height-medium;
  }

  &[data-variant='4'] {
    font-weight: 500;
    font-size: $text-size-medium;
    line-height: $text-line-height-xmedium;

    @include gt-tablet {
      font-size: $text-size-large;
      line-height: $text-line-height-large;
    }
  }

  &[data-variant='5'] {
    font-weight: 400;
    font-size: $text-size-small;
    line-height: $text-line-height-medium;
  }

  &[data-variant='6'] {
    font-weight: 500;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;

    @include gt-tablet {
      font-weight: 700;
      font-size: $text-size-small;
    }
  }

  &[data-variant='7'] {
    font-weight: 500;
    font-size: $text-size-default;
    line-height: $text-line-height-medium;

    @include gt-phone {
      font-size: $text-size-medium;
      line-height: $text-line-height-xmedium;
    }
  }

  /* COLORS
  ========================================================================== */
  &[data-color='white'] {
    color: $c-base-white;
  }

  &[data-color='primary'] {
    color: $c-primary;
  }

  /* TEXT
  ========================================================================== */
  &[data-is-uppercase='true'] {
    text-transform: uppercase;
  }
}
