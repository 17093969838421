@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$modal-gap: 4rem;
$width-icon: 2.13rem;
$height-icon: 2.13rem;

/* MODAL CONTAINER
========================================================================== */
.molecule__modal-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: $s-lg;
  gap: $s-md;

  width: 100%;

  border: none;
  border-radius: 0;
  background: $c-base-white;
  transform: translate(-50 -50%);

  @include gt-phone {
    position: relative;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &[data-invert-exit-icon='true'] {
      justify-content: flex-end;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: $modal-gap;
    width: 100%;
    overflow: auto;
  }

  [data-variant='row'] & {
    .content {
      display: flex;
      flex-direction: row;
    }
  }

  .content__content {
    font-size: $text-size-tiny;
    line-height: $text-line-height-default;
    letter-spacing: 0px;
    text-align: left;

    @include gt-phone {
      font-size: $text-size-default;
      line-height: $text-line-height-medium;
    }

    &[data-is-bold="true"] {
      font-weight: 600
    }
  }
}
