@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* CLOSE BUTTON
========================================================================== */
.atom__close-button-container {
  border: none;
  outline: none;

  width: 32px;
  height: 32px;

  svg {
    width: 100%;
    height: 100%;

    path {
      width: 21.33px;
      height: 21.33px;
    }
  }

  &:hover,
  &:focus {
    opacity: 0.8;
  }
}
