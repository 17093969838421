@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

/* VARIABLES
========================================================================== */
$navigation-hamburger-stick-width: 2.1rem;
$navigation-hamburger-stick-height: 1px;
$sub-navigation-column-gap: 6.4rem;
$open-submenu-height: 60vh;
$submenu-container-bottom-space: 9rem; //An extra space is necessary for the mobile submenu to accomodate the browser bottom commands.
$media-query-min-size: 1281px; //We need this media query to bring the mobile menu sooner than our default media queries. This values also cover zoomed browsers and OS's
$media-query-max-size: 1280px;
$list-item-padding: 4px;

.main-navigation {
  position: relative;

  &[data-is-mobile-menu-active='true'] {
    @include mediaMax($media-query-max-size) {
      .navigation-toogler__burguer {
        height: 0;

        &:before,
        &:after {
          top: 0;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }

      .navigation-list {
        display: flex;
        position: fixed;
        top: $main-header-height;
        left: 0;

        width: 100vw;
        height: 100%;

        padding: $s-md;
        overflow-y: auto;

        background-color: $c-base-white;

        animation: openNavigationModal $basic-transition ease 1 normal forwards;
      }
    }
  }

  /* MENU BUTTON
  ========================================================================== */
  .navigation-toogler {
    border: none;
    transition: opacity ease $basic-transition;

    &:hover,
    &:focus {
      opacity: 0.7;
      transition: opacity ease $basic-transition;
    }

    @include mediaMin($media-query-min-size) {
      display: none;
    }
  }

  .navigation-toogler__burguer {
    display: inline-block;
    position: relative;

    width: $navigation-hamburger-stick-width;
    height: $navigation-hamburger-stick-height;
    background-color: currentColor;

    color: $c-base-black;
    vertical-align: middle;

    &:before,
    &:after {
      position: absolute;
      left: 0;

      width: $navigation-hamburger-stick-width;
      height: $navigation-hamburger-stick-height;
      border-radius: 6px;

      background-color: currentColor;
      content: '';
      transition: all 0.3s;
    }

    &:before {
      top: -$s-zs;
    }

    &:after {
      top: $s-zs;
    }
  }

  .navigation-toggler__text {
    display: flex;
    position: relative;
    align-items: center;

    margin-top: 0.4rem;

    font-size: $text-size-xtiny;
    text-transform: lowercase;
  }

  /* MENU LIST
  ========================================================================== */
  .navigation-list {
    display: none;

    flex-direction: column;
    gap: $s-s;

    @include mediaMin($media-query-min-size) {
      display: inline-flex;
      flex-direction: row;
    }
  }

  .navigation-list__item {
    display: flex;
    padding: $list-item-padding;
  }

  .navigation-list__action {
    @include mediaMax($media-query-max-size) {
      font-weight: 500;
      font-size: $s-s;
      line-height: $text-line-height-medium;
    }
  }

  /* SUB NAVIGATION
  ========================================================================== */
  .sub-navigation-wrapper {
    z-index: $z-index-sky;
    position: fixed;
    top: $main-header-height;
    left: 0;

    width: 100vw;
    height: 100vh;

    @include mediaMax($media-query-max-size) {
      padding: $s-md 0 $main-header-height;
      overflow-y: scroll;

      background-color: $c-base-white;
    }

    @include mediaMin($media-query-min-size) {
      height: calc(100vh - $main-header-height);

      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .sub-navigation__back-button {
    margin-bottom: $s-lg;

    @include mediaMin($media-query-min-size) {
      display: none;
    }
  }

  /* TEXT
  ========================================================================== */
  .sub-navigation__title {
    display: block;
    margin-bottom: $s-s;
    color: $c-gray-4;
    font-weight: normal;

    font-size: $text-size-tiny;
    line-height: $text-line-height-default;
  }

  /* SUB NAVIGATION CONTENT
  ========================================================================== */
  .sub-navigation-content {
    @include mediaMin($media-query-min-size) {
      position: relative;
      padding: $s-lg 0;

      background-color: $c-base-white;

      &:after,
      &:before {
        z-index: $z-index-underground;
        position: absolute;
        top: 0;
        right: 0;
        width: 100vw;

        height: 100%;

        background-color: $c-base-white;
        content: '';

        pointer-events: none;
      }

      &:before {
        left: 0;
      }
    }
  }

  .sub-navigation-content-list {
    @include mediaMax($media-query-max-size) {
      padding-bottom: $submenu-container-bottom-space;
    }

    @include mediaMin($media-query-min-size) {
      display: inline-block;
      column-gap: $sub-navigation-column-gap;

      height: $open-submenu-height;
      columns: calc(27rem - ($sub-navigation-column-gap / 2));
      column-rule: 2px solid $c-gray-2;
    }
  }

  .sub-navigation-content-list__item {
    position: relative;

    margin-bottom: $s-lg;

    break-inside: avoid;
  }

  /* LIST ITEMS
  ========================================================================== */
  .sub-navigation-list {
    display: flex;
    flex-direction: column;
    gap: $s-zs;
  }

  /* EXTRA LINKS
  ========================================================================== */
  .sub-navigation-extra-list {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    gap: $s-zs;
  }

  /* CLOSE MENU BUTTON
  ========================================================================== */
  .close-button {
    position: absolute;
    top: $s-lg;
    right: 0;

    border: none;

    color: $c-base-black;
  }

  .close-button__icon {
    width: 2.4rem;
    height: 2.4rem;

    path {
      stroke: currentColor;
      stroke-width: 1px;
    }
  }

  /* ANIMATIONS
  ========================================================================== */
  // OPEN NAVIGATION MODAL ANIMATION
  @keyframes openNavigationModal {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}